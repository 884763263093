<!-- 角色管理 -->
<template>
    <div>
        <ctrl-box class="ctrl-box" :data="{}">
            <template >
                <div class="xgroup">
                    <el-link :underline="false" @click="doAdd()" v-if="hasPermission('role:add')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-tianjia" font-size="27px;"/>
                            <span class="txt">{{msg('添加')}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="copyRole" v-if="hasPermission('role:add')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-fuzhi" font-size="27px;"/>
                            <span class="txt">
                                {{msg("复制角色")}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doEdit()" v-if="hasPermission('role:update')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-bianji" font-size="27px;"/>
                            <span class="txt">{{msg('编辑')}}</span>      
                        </div>
                    </el-link>
                </div>
                <div class="xgroup">
                    <el-link :underline="false" @click="grantMenu" v-if="hasPermission('role:grantMenu')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-caidanguanli" font-size="27px;"/>
                            <span class="txt">
                                {{msg("分配菜单")}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="grantPermission" v-if="hasPermission('role:grantPermission')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-quanxian1" font-size="27px;"/>
                            <span class="txt">
                                {{msg("分配功能权限")}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="grantColumn" v-if="hasPermission('role:grantColumns')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-a-kuozhanicon_huaban1fuben9" font-size="27px;"/>
                            <span class="txt">
                                {{msg("分配字段权限")}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="grantButton" v-if="hasPermission('role:grantBtns')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-anniu1" font-size="27px;"/>
                            <span class="txt">
                                {{msg("分配按钮权限")}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="grantRoleRefs" v-if="hasPermission('role:grantRoleRefs')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-dengluciyonghu" font-size="27px;"/>
                            <span class="txt">
                                {{msg("分配引用角色")}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="grantView" v-if="hasPermission('role:grantView')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-buju1" font-size="27px;"/>
                            <span class="txt">
                                {{msg("分配视图")}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" type="danger" @click="doDelete()" v-if="hasPermission('role:delete')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-shanchu1" font-size="27px;"/>
                            <span class="txt">{{msg('删除')}}</span>      
                        </div>            
                    </el-link>
                </div>
            </template>
        </ctrl-box>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" v-if="refresh" :height="docHeight"  show-overflow row-key
                border
                resizable
                highlight-current-row
                highlight-hover-row
                :data="roles"
                :columns="columns"></vxe-grid>
        </div>

        <el-dialog :title="dgTitle" :visible.sync="dgVisible" :fullscreen="isMinScreen" :close-on-click-modal="false">
            <role-edit :role="currRole" v-on:close-dg="closeEditDialog" ref="roleEdit"/>
        </el-dialog>

        <el-dialog :title="msg('分配菜单')" :visible.sync="grantMenuVisiable" :fullscreen="isMinScreen"
            :close-on-click-modal="false"
            @opened="opendGrantMenu"
        >
            <grant-menu :role-id="currRole.id" 
                v-on:close-dg="closeGrantMenuDg" ref="grantMenu"/>
        </el-dialog>

        <el-dialog :title="msg('分配功能权限')" :visible.sync="grantPermissionVisiable" :fullscreen="isMinScreen"
            :close-on-click-modal="false"
            @opened="opendGrantPermission"
        >
            <grant-permission :role-id="currRole.id" 
                v-on:close-dg="closeGrantPermissionDg" ref="grantPermission"/>
        </el-dialog>

        <el-dialog :title="msg('分配字段权限')" width="650px" :visible.sync="grantColumnVisiable" :fullscreen="isMinScreen"
            :close-on-click-modal="false"
            @opened="opendGrantColumn"
        >
            <grant-column :role-id="currRole.id" 
                v-on:close-dg="closeGrantColumnDg" ref="grantColumn"/>
        </el-dialog>

        <el-dialog :title="msg('分配按钮权限')" width="650px" :visible.sync="grantButtonVisiable" :fullscreen="isMinScreen"
            :close-on-click-modal="false"
            @opened="opendGrantButton"
        >
            <grant-button :role-id="currRole.id" 
                v-on:close-dg="closeGrantButtonDg" ref="grantButton"/>
        </el-dialog>

        <el-dialog :title="msg('分配引用角色')" width="450px" 
            :visible.sync="grantRoleRefsVisiable"
            :fullscreen="isMinScreen"
            :close-on-click-modal="false"
            @opened="opendGrantRoleRefs"
        >
            <grant-role-refs :role-id="currRole.id" :roles = "roles"
                v-on:close-dg="closeGrantRoleRefsDg" ref="grantRoleRefs"/>
        </el-dialog>
        <el-dialog :title="msg('分配视图')" width="650px" 
            :visible.sync="grantViewVisiable" 
            :fullscreen="isMinScreen"
            :close-on-click-modal="false"
            @opened="opendGrantView"
        >
            <grant-view :role-id="currRole.id" 
                v-on:close-dg="closeGrantViewDg" ref="grantViewRef"/>
        </el-dialog>

        <el-dialog
            :title="msg('角色复制')"
            :visible.sync="copyVisiable"
            :close-on-click-modal="false"
            width="30%">
            <el-form ref="form" :model="copyForm" label-width="80px">
                <el-form-item :label="msg('角色名')">
                    <el-input v-model="copyForm.label" :placeholder="msg('请输入新的角色名')"></el-input>
                </el-form-item>
                <el-form-item :label="msg('角色号')">
                    <el-input v-model="copyForm.xvalue" :placeholder="msg('请输入新的角色号')"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="copyVisiable = false">{{msg('取消')}}</el-button>
                <el-button type="primary" @click="doCopyRole()">{{msg('确定')}}</el-button>
            </span>
        </el-dialog>

    </div>
</template> 

<script>
import CtrlBox from "../../components/CtrlBox.vue";
import RoleEdit from "./RoleEdit.vue";
import GrantMenu from "./RoleGrantMenu.vue";
import GrantPermission from "./RoleGrantPermission.vue";
import GrantColumn from "./RoleGrantColumn.vue";
import GrantButton from "./RoleGrantButton.vue";
import GrantRoleRefs from "./RoleGrantRefs.vue";
import GrantView from "./RoleGrantView.vue";

export default {
    name: 'Role',
    components: {
        CtrlBox: CtrlBox,
        RoleEdit:RoleEdit,
        GrantMenu: GrantMenu,
        GrantPermission: GrantPermission,
        GrantColumn: GrantColumn,
        GrantButton:GrantButton,
        GrantRoleRefs:GrantRoleRefs,
        GrantView:GrantView,
    },
    mounted(){
        this.buildColumns();
        this.getRoles();
    },
    data(){
        return {
            query:{
                url: null,
                name: null
            },
            showQuery: false,
            columns:[],
            roles: [],      //列表数据
            currRole: {id: null},

            refresh: true,

            dgTitle: '',
            dgVisible: false,
            
            grantMenuVisiable: false,
            grantPermissionVisiable: false,
            grantColumnVisiable: false,
            grantButtonVisiable:false,

            grantRoleRefsVisiable:false,
            grantViewVisiable:false,
            copyVisiable:false,
            copyForm:{
                label:null,
                xvalue:null,
            }

        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                return this.$store.state.isMinScreen;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 160;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    methods:{
        buildColumns(){
            this.columns = [
                {field: "id", title: this.msg("ID"), width: 80},
                {field: "label", title: this.msg("角色名"), treeNode: true, minWidth: this.isMinScreen?400:250, width: this.isMinScreen?400: 250},
                // {field: "path", title: this.msg("路径"), width: 150},
                {field: "xvalue", title: this.msg("角色号"), minWidth:120,width:120},
                {field: "fullname", title: this.msg("创建人"), minWidth:120,width:120},
            ]
        },
        getRoles(){
            this.$axios.post(this.$kit.api.role.list, this.query)
                .then((result) => {
                    if(result.status){
                        let data = result.data;
                        this.roles = data;
                    }
                });
        },
        doAdd(){
            this.currRole = {
                id: null,
                label: null,
                xvalue: null,
            };

            this.dgTitle = this.msg("新增角色");
            this.dgVisible = true;

        },
        closeEditDialog(type){  //编辑弹窗关闭后的回调事件
            this.getRoles();
            this.dgVisible = false;
        },
        doEdit(){
            var row = this.$refs.dataTable.getCurrentRecord();
            if(!row){
                this.$message(this.msg('请选中数据后再进行操作'));
                return;
            }
            if(row.id==1){
                this.$message(this.msg('根节点不能编辑'));
                return ;
            }
            if(row.id==0){
                return ;
            }

            this.currRole = row;
            this.dgTitle = this.msg("编辑角色")+":"+row.label;
            this.dgVisible = true;
        },
        doDelete(){
            var row = this.$refs.dataTable.getCurrentRecord();
            if(!row){
                this.$message(this.msg('请选中数据后在进行操作'));
                return;
            }

            var _this = this;
            this.$confirm(this.fmt('确定要删除角色《%s》吗?', [row.label]), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.get(_this.$kit.api.role.delete+"?id="+row.id)
                .then((result) => {
                    _this.$message(result.msg || _this.msg('删除成功'));
                    _this.getRoles(true);
                });
            }).catch((err) => {
                // console.log(err);
            });
        },
        grantMenu(){
            var row = this.$refs.dataTable.getCurrentRecord();
            if(!row){
                this.$message(this.msg('请选中数据后再进行操作'));
                return;
            }

            this.currRole = row;
            this.grantMenuVisiable = true;
        },
        opendGrantMenu(){
            this.$nextTick(() => {
                this.$refs.grantMenu.buildRoleMenus(this.currRole.id);
            });
        },
        closeGrantMenuDg(type){
            this.grantMenuVisiable = false;
        },
        grantPermission(){
            var row = this.$refs.dataTable.getCurrentRecord();
            if(!row){
                this.$message(this.msg('请选中数据后再进行操作'));
                return;
            }

            this.currRole = row;
            this.grantPermissionVisiable = true;
        },
        opendGrantPermission(){
            this.$nextTick(() => {
                this.$refs.grantPermission.initPermissions(this.currRole.id);
            });
        },
        closeGrantPermissionDg(type){
            this.grantPermissionVisiable = false;
        },
        grantColumn(){
            var row = this.$refs.dataTable.getCurrentRecord();
            if(!row){
                this.$message(this.msg('请选中数据后再进行操作'));
                return;
            }

            this.currRole = row;
            this.grantColumnVisiable = true;
        },
        opendGrantColumn(){
            this.$nextTick(() => {
                this.$refs.grantColumn.initView(this.currRole.id);
            });
        },
        closeGrantColumnDg(){
            this.grantColumnVisiable = false;
        },
        grantButton(){
            var row = this.$refs.dataTable.getCurrentRecord();
            if(!row){
                this.$message(this.msg('请选中数据后再进行操作'));
                return;
            }

            this.currRole = row;
            this.grantButtonVisiable = true;
        },
        opendGrantButton(){
            this.$nextTick(() => {
                this.$refs.grantButton.initView(this.currRole.id);
            });
        },
        closeGrantButtonDg(){
            this.grantButtonVisiable = false;
        },

        //分配分配引用角色
        grantRoleRefs(){
            var row = this.$refs.dataTable.getCurrentRecord();
            if(!row){
                this.$message(this.msg('请选中数据后再进行操作'));
                return;
            }

            this.currRole = row;
            this.grantRoleRefsVisiable = true;
        },
        opendGrantRoleRefs(){
            this.$nextTick(() => {
                this.$refs.grantRoleRefs.init(this.currRole.id);
            });
        },
        closeGrantRoleRefsDg(){
            this.grantRoleRefsVisiable = false;
        },

        //分配分配视图
        grantView(){
            var row = this.$refs.dataTable.getCurrentRecord();
            if(!row){
                this.$message(this.msg('请选中数据后再进行操作'));
                return;
            }

            this.currRole = row;
            this.grantViewVisiable = true;
        },
        opendGrantView(){
            this.$nextTick(() => {
                this.$refs.grantViewRef.init(this.currRole.id);
            });
        },
        closeGrantViewDg(){
            this.grantViewVisiable = false;
        },
        //复制角色
        copyRole(){
            var row = this.$refs.dataTable.getCurrentRecord();
            if(!row){
                this.$message(this.msg('请选中数据后再进行操作'));
                return;
            }
            this.copyForm={
                label:null,
                xvalue:null,
            }
            this.copyVisiable = true;
        },
        doCopyRole(){
            var row = this.$refs.dataTable.getCurrentRecord();
            var _this = this;
            this.$axios.post(this.$kit.api.role.copyRole+"?roleId="+row.id, this.copyForm)
            .then(function(result){
                if(result.status){
                    _this.$message(result.msg || _this.msg("创建成功"));
                    if(result.status){
                        _this.copyVisiable = false;
                        _this.getRoles();
                    }
                }else{
                    _this.$message(result.msg || _this.msg('创建失败'));
                }
               
            });
        },
    }
}

</script>

<style scoped lang="scss">

.ctrl-box a{
    padding-left:5px; 
    padding-right:5px;
}
</style>