<template>
<div>
  <template v-if="table">
    <div class="category" v-for="(tbs, key) in table.tables" :key="key">
      <h3>{{dicts.viewCategories[key].label}}</h3>
      <span class="box" v-for="(tb) in tbs" :key="tb.alias">
        <h4 >{{tb.tableLable}}<el-checkbox v-model="tb.checked" @change="(val) => checkedAll(val, tb)" style="margin-left:20px;">全选</el-checkbox></h4>
        <div class="columns">
          <el-checkbox  v-for="col in tb.columns" :key="col.colName" v-model="col.checked">{{col.label}}</el-checkbox>
        </div>
      </span>
    </div>
    <div style="text-align:center; margin-top:15px; margin-bottom:15px;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>
  </template>
</div>
</template> 

<script>
// @ is an alias to /src

export default {
  name: 'RoleGrantColumn',
  mounted:function(){
  },
  props:{
    roleId: Number
  },
  data(){
    return{
      table: null,
    }
  }, 
  methods: {
    initView(roleId){ 
      var _this = this;

      var table = this.$refs.perTable;

      _this.table =  null;

      this.axios.get(this.$kit.api.viewSetting.checked+"?roleId="+roleId)
        .then(function (result) {
        if(result.status){
          let data = result.data;
          for(var key in data.tables){
            let arr = data.tables[key];
            arr.forEach(tb => {
              tb["checked"] = false;
            });
          }
          
          _this.table =  data;
        }else{
          _this.$message(result.msg); 
        }
        
      }).catch(function (error) {
        console.error(error);
      });
    }, 
    checkedAll(checked, tb){
      tb.columns.forEach(col => {
        col.checked = checked;
      })
    },
    onSubmit(){
        var _this=this;
        //{roleId: 1, permissionId: "3"}
        var rps = [];
        Object.keys(this.table.tables).forEach(k => {
          this.table.tables[k].forEach(t => {
            t.columns.forEach(c => {
              if(c.checked){
                c.type = k,
                c.tableAlias = t.alias;
                rps.push(c);
              }
            })
          });
        });

        this.$axios.post(this.$kit.api.role.grantCol+"?roleId="+this.roleId, rps)
            .then(function(result){
                if(result.status){
                    _this.$message(result.msg || _this.msg('更新成功'));
                    if(result.status){
                      _this.$emit("close-dg", true);
                    }
                }else{
                    _this.$message(result.msg || _this.msg('更新失败'));
                }
               
            });
    },

  },

  

}
</script>

<style scoped lang="scss">

.category h3{background: #fdf5e6; padding:15px;}
.category .box{padding: 0px 15px; display: block;}
</style>
