<template>
  <div style="max-height:600px;overflow:auto;">
    <el-row :span="4">
      <span v-for="(btn, idx) in btns" :key="idx">
        <el-checkbox
            border size="mini" class="pts-checkbox"
            v-model="btn.checked" :label="msg(btn.name)" >
          </el-checkbox>
          <template v-if="btn.checked && btn.children && btn.children.length>0">
            <span v-for="(sub, idx1) in btn.children" :key="idx1">
              <el-checkbox
                border size="mini" class="pts-checkbox"
                v-model="sub.checked" :label="msg(sub.name)">
              </el-checkbox>
            </span>
          </template>
      </span>
    </el-row>
    <div style="text-align:center; margin-top:15px; margin-bottom:15px;">
      <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
      <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>
  </div>
</template> 

<script>
// @ is an alias to /src

export default {
  name: 'RoleGrantColumn',
  mounted:function(){
  },
  props:{
    roleId: Number
  },
  data(){
    return{
      table: null,
      btns:[],
    }
  }, 
  methods: {
    initView(roleId){ 
      let user  = JSON.parse(localStorage.getItem("user"));

      var _this = this;

      var table = this.$refs.perTable;

      _this.table =  null;

      this.axios.get(this.$kit.api.role.btnsByCheck+"?roleId="+roleId)
        .then((result) => {
          if(result.status){
              let parents = result.data.parent;
              let curr = result.data.curr;
              
              let stockBtns = this.dicts.stockBtns;
              let newBtns = [];
              stockBtns.forEach(b => {
                  if(parents.indexOf(b.name)>-1 || user.roleId == 1){
                      let nb = JSON.parse(JSON.stringify(b));
                      if(curr.indexOf(b.name) > -1){
                          nb.checked = true;
                      }else{
                        nb.checked = false;
                      }

                      newBtns.push(nb);
                      let children = nb.children;
                      if(children && children.length>0){
                          nb.children = [];
                          children.forEach(sub => {
                              if(parents.indexOf(sub.name)>-1 || user.roleId == 1){
                                if(curr.indexOf(sub.name) > -1){
                                  sub.checked = true;
                                  }else{
                                    sub.checked = false;
                                  }
                                  nb.children.push(sub);
                              }
                          })
                      }
                      
                  }
              });
              this.btns = newBtns;
          }
        
      }).catch(function (error) {
        console.error(error);
      });
    }, 
    checkedAll(checked, tb){
      tb.columns.forEach(col => {
        col.checked = checked;
      })
    },
    onSubmit(){
      let nbtns = [];
      this.btns.forEach(b => {
        if(b.checked){
            nbtns.push({btnName:b.name});

            if(b.children && b.children.length>0){
                b.children.forEach(sub => {
                    if(sub.checked){
                        nbtns.push({btnName:sub.name});
                    }
                });
            }

        }
      });
    this.$axios.post(this.$kit.api.role.updateViewBtns+'?roleId='+this.roleId,nbtns)
        .then(result => {
            if(result.status){
                this.$message(result.msg || this.msg('更新成功'));
                if(result.status){
                  this.$emit("close-dg", true);
                }
            }else{
                this.$message(result.msg || this.msg('更新失败'));
            }
        });
    },

  },

  

}
</script>

<style scoped lang="scss">
.pts-checkbox{
    margin: 5px 15px 5px 0px !important;
}
</style>
