<template>
<div>
  <vxe-grid ref="perTable" height="450px" 
      resizable border
      show-overflow highlight-hover-row
      highlight-current-row highlight-hover-column highlight-current-column
      :data="roles"
      :columns="columns"
  ></vxe-grid>
  <div style="text-align:center; margin-top:15px; margin-bottom:15px;">
      <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
      <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
  </div>
</div>
</template> 

<script>
// @ is an alias to /src

export default {
  name: 'RoleGrantRefs',
  mounted:function(){
  },
  props:{
    roleId: Number,
    roles: Array,
  },
  data(){
    return{
      columns: [
        { type: 'checkbox', width: 60 },
        {field: "id", title: this.msg("ID"), width: 100},
        {field: "label", title: this.msg("角色名"),width: 150},
      ],
    }
  }, 
  methods: {
    init(roleId){ 
      this.$refs.perTable.clearCheckboxRow()
      this.axios.get(this.$kit.api.role.roleRefs+"?roleId="+roleId)
        .then((result) => {
          if(result.status){
            
            let refs = result.data;
            
            if(refs){
              refs = refs.split(",");
            }
            
            this.roles.forEach(role => {
              let checks = [];
              if(refs && refs.indexOf(role.id+"")>-1){
                checks.push(role);
              }
              
              if(checks.length>0){
                setTimeout(()=>{
                  this.$refs.perTable.setCheckboxRow(checks, true)
                }, 100)
              }
            });
          }else{
            this.$message(result.msg); 
          }
          
        }).catch((error) => {
          console.error(error);
        });
    }, 
    onSubmit(){
        //{roleId: 1, permissionId: "3"}
        var selectes = this.$refs.perTable.getCheckboxRecords();
        var rps = [];
        selectes.forEach(s => rps.push(s.id));

        this.$axios.post(this.$kit.api.role.updateRoleRefs+"?roleId="+this.roleId+"&ids="+rps.join(","))
          .then( result =>{
              if(result.status){
                  this.$message(result.msg || this.msg('更新成功'));
                  if(result.status){
                    this.$emit("close-dg", true);
                  }
              }else{
                  this.$message(result.msg || this.msg('更新失败'));
              }
              
          });
    },

  },

  

}
</script>

<style scoped lang="scss">


</style>
