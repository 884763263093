<template>
<el-form ref="form" :model="role" label-width="80px" size="mini">
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('角色名')">
                <el-input v-model="role.label"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('角色号')">
                <el-input v-model="role.xvalue"></el-input>
            </el-form-item>
        </el-col>
    </el-row>

    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>

</el-form> 
</template>

<script>
export default {
  name: 'RoleEdit',
  data(){
    return{
        rolesPopoverVisiable:false,
    }
  },
  props:{
      role: Object,
  },
  mounted: function(){
  },
  methods:{
    onSubmit(){

        var url = null;
        if(this.role.id==null){
            url = this.$kit.api.role.create;
        }else{
            url = this.$kit.api.role.update;
        }

        var _this = this;
        this.$axios.post(url, this.role)
            .then(function(result){
                if(result.status){
                    _this.$message(result.msg || (_this.id==null? _this.msg("创建成功"): _this.msg('更新成功')));
                    if(result.status){
                        _this.$emit("close-dg", true);
                    }
                }else{
                    _this.$message(result.msg || (_this.id==null? _this.msg('创建失败'):_this.msg('更新失败')));
                }
               
            });
    }
  }
}
</script>

<style scoped lang="scss">
.icon-list{ margin:0px; padding:0px; display:flex; flex-wrap:wrap;  border: 1px solid #eaeefb;}
.icon-list li{cursor:pointer;
        padding:8px; border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
        display:flex; flex-direction: column; justify-content: center; align-items:center;
}
.icon-list li i{font-size:18px;} 
.icon-list li:hover{color:#5CB6FF;}


</style>
