<template>
<div>
  <el-tree
    :data="menus" show-checkbox 
    default-expand-all
    node-key="id" 
    ref="menuTree"
    :props="defaultProps">
  </el-tree>
  <div style="text-align:center; margin-top:15px; margin-bottom:15px;">
      <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
      <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
  </div>
</div>
</template> 

<script>
// @ is an alias to /src

export default {
  name: 'RoleGrantMenu',
  mounted:function(){},
  data(){
    return{
      checkedKeys:[],
      checkedNodes:[],
      menus: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    }
  }, 
  props: {
      roleId: Number
  },
  methods: {
    buildRoleMenus(roleId){ 
      var _this = this;
      this.axios.get(this.$kit.api.menu.toggle+"?id="+roleId).then(function (result) {
        if(result.status){
          _this.menus = [];
          _this.checkedKeys = [];
          _this.checkedNodes = [];
         
          //debugger;
          // _this.getTree(result.data);
          var ms = _this.$kit.buildTree(result.data, false);
          _this.menus = ms;

          _this.getChecked(_this.menus);
          setTimeout(function(){
            _this.$refs.menuTree.setCheckedKeys(_this.checkedKeys);
          }, 500);

        }else{
          _this.$message(result.msg); 
        }
        
      }).catch(function (error) {
        console.error(error);
      });
    }, 

    onSubmit(){
      var _this = this;
      var menuTree = _this.$refs.menuTree;
      var selectedKeys = menuTree.getCheckedKeys();
      var halfSelectedKeys = menuTree.getHalfCheckedKeys();

      if(halfSelectedKeys.length>0){
        halfSelectedKeys.forEach(key => selectedKeys.push(key));
      }

      // console.log(selectedKeys, halfSelectedKeys);
      var roleMenus = [];
      for(let i=0; i<selectedKeys.length; i++){
        roleMenus.push({roleId: _this.roleId, menuId: selectedKeys[i]});
      }

      this.$axios.post(this.$kit.api.role.menuUpdate+"?roleId="+this.roleId, roleMenus)
            .then(function(result){
                if(result.status){
                    _this.$alert(result.msg || _this.msg('更新成功'), _this.msg('提示'), {
                        confirmButtonText: _this.msg('确定'),
                        callback: action => {
                            if(result.status){
                                _this.$emit("close-dg", true);
                            }
                        }
                    });
                }else{
                    _this.$message(result.msg || _this.msg('更新失败'));
                }
            });
    },

    getChecked(menus){
      for(var i=0; i<menus.length; i++){
        var item = menus[i];
        var children = item['children'] || [];

        if(children.length==0 && item.checked){
          this.checkedKeys.push(item.id);
          this.checkedNodes.push(item);
        }else{
          this.getChecked(children); 
        }
      }
    }
  },

  

}
</script>

<style scoped lang="scss">


</style>
