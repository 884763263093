<template>
<div>
  <vxe-grid ref="perTable" height="450px" 
      resizable border
      show-overflow highlight-hover-row
      highlight-current-row highlight-hover-column highlight-current-column
      :data="views"
      :columns="columns"
  ></vxe-grid>
  <div style="text-align:center; margin-top:15px; margin-bottom:15px;">
      <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
      <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
  </div>
</div>
</template> 

<script>
// @ is an alias to /src

export default {
  name: 'RoleGrantRefs',
  mounted:function(){
  },
  props:{
    roleId: Number,
  },
  data(){
    return{
      columns: [
        { type: 'checkbox', width: 60 },
        {field: "id", title: this.msg("ID"), width: 80},
        {field: "name", title: this.msg("视图名")},
        {field: "category", title: this.msg("视图类别"), 
          slots: {
            default: ({ row }) => {
              return row.category==1?"库存视图":"账户视图"
            },
          },
        },
      ],
      views: [],
    }
  }, 
  methods: {
    init(roleId){ 
      this.views = [];

      this.$refs.perTable.clearCheckboxRow();

      this.axios.post(this.$kit.api.viewSetting.list, {})
        .then((result) => {
          if(result.status){
            
            let views = result.data;
            if(views.length >0){

              this.views = views;
              this.$axios.get(this.$kit.api.role.viewUsers+"?roleId="+this.roleId)
                .then(rs =>{
                  if(rs.status){
                      let refs = rs.data;
                      if(refs && refs.length>0){
                        let checks = [];
                        refs = refs.map(r => r.viewId);
                        this.views.forEach(v => {
                          if(refs && refs.indexOf(v.id)>-1){
                            checks.push(v);
                          }
                        });

                        setTimeout(()=>{
                          this.$refs.perTable.setCheckboxRow(checks, true)
                        }, 100)
                      }
                    }else{
                        this.$message(result.msg || this.msg('获取数据失败'));
                    }
                });
            }
          }else{
            this.$message(result.msg || this.msg('获取数据失败')); 
          }
          
        }).catch((error) => {
          console.error(error);
        });
    }, 
    onSubmit(){
        var _this=this;
        //{roleId: 1, permissionId: "3"}
        var selectes = this.$refs.perTable.getCheckboxRecords();
        var rps = [];
        selectes.forEach(s => rps.push(s.id));

        this.$axios.post(this.$kit.api.role.updateViews+"?roleId="+this.roleId+"&ids="+rps.join(","))
          .then( result =>{
              if(result.status){
                  this.$message(result.msg || this.msg('更新成功'));
                  if(result.status){
                    this.$emit("close-dg", true);
                  }
              }else{
                  this.$message(result.msg || this.msg('更新失败'));
              }
              
          });
    },

  },

  

}
</script>

<style scoped lang="scss">


</style>
