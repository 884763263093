<template>
<div>
  <el-input :placeholder="msg('搜索权限')" v-model="inputTxt" size="mini" v-on:keyup.enter.native="searchPerms" clearable>    
      <i slot="prefix" class="el-input__icon el-icon-search"></i>
  </el-input>
  <vxe-grid ref="perTable" height="450px" 
      resizable border
      show-overflow highlight-hover-row
      highlight-current-row highlight-hover-column highlight-current-column
      :data="permissions"
      :columns="columns"
  ></vxe-grid>
  <div style="text-align:center; margin-top:15px; margin-bottom:15px;">
      <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
      <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
  </div>
</div>
</template> 

<script>
// @ is an alias to /src

export default {
  name: 'RoleGrantPermission',
  mounted:function(){
  },
  props:{
    roleId: Number
  },
  data(){
    return{
      inputTxt: null,
      columns: [
        { type: 'checkbox', width: 60 },
        {field: "id", title: this.msg("ID"), width: 80},
        {field: "label", title: this.msg("权限名")},
        {field: "pattern", title: this.msg("权限表达式")},
      ],
      permissions: [],
      searchIndex: -1
    }
  }, 
  methods: {
    initPermissions(roleId){ 
      var _this = this;

      var table = this.$refs.perTable;

      _this.permissions =  [];

      this.axios.get(this.$kit.api.permission.toggle+"?roleId="+roleId).then(function (result) {
        if(result.status){
          _this.permissions =  result.data;
          setTimeout(()=>{
            _this.$refs.perTable.setCheckboxRow(result.data.filter(p => p.checked), true)
          }, 100)
        }else{
          _this.$message(result.msg); 
        }
        
      }).catch(function (error) {
        console.error(error);
      });
    }, 
    onSubmit(){
        var _this=this;
        //{roleId: 1, permissionId: "3"}
        var selectes = this.$refs.perTable.getCheckboxRecords();
        var rps = [];
        selectes.forEach(s => rps.push({roleId: this.roleId, permissionId: s.id}));

        this.$axios.post(this.$kit.api.role.grantPermission+"?roleId="+this.roleId, rps)
            .then(function(result){
                if(result.status){
                    _this.$message(result.msg || _this.msg('更新成功'));
                    if(result.status){
                      _this.$emit("close-dg", true);
                    }
                }else{
                    _this.$message(result.msg || _this.msg('更新失败'));
                }
               
            });
    },
    searchPerms(){
        
        if(!this.inputTxt) {
          this.searchIndex = -1;
        }

        let {fullData} = this.$refs.perTable.getTableData();
        let row = fullData.filter(d => d.label.indexOf(this.inputTxt)>-1);
        if(row && row.length>0){
          let idx = 0;
          if(this.searchIndex==-1){
            idx = 0;
          }else{
            idx = this.searchIndex + 1;
            if(idx>row.length){
              idx = 0;
            }
          }
          this.searchIndex = idx;
          this.$refs.perTable.scrollToRow(row[idx]);
          this.$refs.perTable.setCurrentRow(row[idx]);
        }

    }

  },

  

}
</script>

<style scoped lang="scss">


</style>
